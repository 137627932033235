<template>
  <b-container fluid>
    <b-row class="mb-4">
      <b-col cols="12">
        <h1>Tracks</h1>
      </b-col>

      <b-col cols="6">
        <label for="text-search" class="mr-3">Zoeken</label>
        <b-form-input id="text-search" class="inline mr-4" @keyup.enter="setSearch" v-model="filter.q"></b-form-input><br>
        <p class="mt-2 mb-0">Je kunt zoeken op Artiest, Titel, BOEP ID en ISRC.</p>
      </b-col>

      <b-col cols="3">
        <b-form-checkbox
          id="checkbox-1"
          v-model="filter.PreferredSource"
          name="pref-source"
          value=""
          unchecked-value="1"
        >
          Toon verborgen tracks
        </b-form-checkbox>
      </b-col>
    </b-row>

    <b-row>
      <b-col cols="6">
        <label for="label-select">Label</label>
        <v-select
          v-model="filter.LabelID"
          id="label-select"
          placeholder="---- Alles ----"
          label="Name"
          :reduce="label => label.LabelID"
          :options="labelOptions">
        </v-select>
      </b-col>

      <b-col cols="3" style="padding-top: 1.75em;">
        <b-button variant="primary" class="mr-2" style="width: 45%;" v-on:click.prevent="setSearch">Zoeken</b-button>
        <b-button variant="white" style="width: 45%;" v-on:click.prevent="resetSearch">Reset</b-button>
      </b-col>
    </b-row>

    <b-row class="mt-3">
      <b-col>
        <track-list :filter="activeFilter" :start-at="startAt" :show-preferred-source="activeFilter.PreferredSource == ''" />
      </b-col>
    </b-row>  
  </b-container>
</template>

<script>
  import vSelect from 'vue-select'

  export default {
    name: 'Tracks',
    components: {
      vSelect,
      'track-list': () => import('@/components/TrackList.vue'),
    },
    data () {
      return {
        labelOptions: [],
        filter: {
          q: '',
          LabelID: '',
          PreferredSource: ''
        }
      }
    },
    props: {
      activeFilter: Object,
      startAt: Number,
    },
    watch: {
      '$route' () {
        this.loadFilter()
      }
    },
    methods: {
      loadFilter () {
        // Load filter from query
        this.filter.q = this.activeFilter.q
        this.filter.LabelID = this.activeFilter.LabelID
        this.filter.PreferredSource = this.activeFilter.PreferredSource
      },
      setSearch () {
        if (JSON.stringify(this.filter) == JSON.stringify(this.activeFilter)) return
        const search = {
          'start-at': 1,
          q: this.filter.q,
          labelId: this.filter.LabelID,
          preferredSource: this.filter.PreferredSource
        }
        
        this.$router.push({ 
          query: search
        })
      },
      resetSearch() {
        this.filter.q = ''
        this.filter.LabelID = ''
        this.filter.PreferredSource = ''
        
        this.setSearch()
      }
    },
    beforeRouteLeave (to, from, next) {
      localStorage.setItem('search-track-last', JSON.stringify(from.query))
      next()
    },
    mounted () {
      this.$root.$data.crumbs = [
        {to: { name: 'track-search' }, text: 'Tracks'}
      ]

      this.loadFilter()

      // Load label options
      let ctx = {
        perPage: 10000,
        currentPage: 1,
      }
      this.$http
        .get('labels', { params: ctx })
        .then(response => {
          this.labelOptions = response.data
        })
    }
  }
</script>

<style scoped>
  label {
    font-weight: bold;
  }

  v-select {
    height: calc(1.5em + 0.75rem + 2px);
  }

  .inline {
    width: auto;
    display: inline-block;
  }
</style>