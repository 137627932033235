var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-4" },
        [
          _c("b-col", { attrs: { cols: "12" } }, [
            _c("h1", [_vm._v("Tracks")])
          ]),
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c(
                "label",
                { staticClass: "mr-3", attrs: { for: "text-search" } },
                [_vm._v("Zoeken")]
              ),
              _c("b-form-input", {
                staticClass: "inline mr-4",
                attrs: { id: "text-search" },
                on: {
                  keyup: function($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.setSearch($event)
                  }
                },
                model: {
                  value: _vm.filter.q,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "q", $$v)
                  },
                  expression: "filter.q"
                }
              }),
              _c("br"),
              _c("p", { staticClass: "mt-2 mb-0" }, [
                _vm._v("Je kunt zoeken op Artiest, Titel, BOEP ID en ISRC.")
              ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "3" } },
            [
              _c(
                "b-form-checkbox",
                {
                  attrs: {
                    id: "checkbox-1",
                    name: "pref-source",
                    value: "",
                    "unchecked-value": "1"
                  },
                  model: {
                    value: _vm.filter.PreferredSource,
                    callback: function($$v) {
                      _vm.$set(_vm.filter, "PreferredSource", $$v)
                    },
                    expression: "filter.PreferredSource"
                  }
                },
                [_vm._v(" Toon verborgen tracks ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("label", { attrs: { for: "label-select" } }, [
                _vm._v("Label")
              ]),
              _c("v-select", {
                attrs: {
                  id: "label-select",
                  placeholder: "---- Alles ----",
                  label: "Name",
                  reduce: function(label) {
                    return label.LabelID
                  },
                  options: _vm.labelOptions
                },
                model: {
                  value: _vm.filter.LabelID,
                  callback: function($$v) {
                    _vm.$set(_vm.filter, "LabelID", $$v)
                  },
                  expression: "filter.LabelID"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            { staticStyle: { "padding-top": "1.75em" }, attrs: { cols: "3" } },
            [
              _c(
                "b-button",
                {
                  staticClass: "mr-2",
                  staticStyle: { width: "45%" },
                  attrs: { variant: "primary" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.setSearch($event)
                    }
                  }
                },
                [_vm._v("Zoeken")]
              ),
              _c(
                "b-button",
                {
                  staticStyle: { width: "45%" },
                  attrs: { variant: "white" },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.resetSearch($event)
                    }
                  }
                },
                [_vm._v("Reset")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mt-3" },
        [
          _c(
            "b-col",
            [
              _c("track-list", {
                attrs: {
                  filter: _vm.activeFilter,
                  "start-at": _vm.startAt,
                  "show-preferred-source":
                    _vm.activeFilter.PreferredSource == ""
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }